import { DateTimeWrapper } from "./styledComponents"

const DateTimeBlock:React.FC = () => {
    let dow: string[] = ["日", "月", "火", "水", "木", "金", "土"];
    let date: Date = new Date();

    return (
        <DateTimeWrapper>
            {date.getMonth() + 1}/{date.getDate()}
            <span className="dow">（{dow[date.getDay()]}）</span>
            {' ' + ('0' + date.getHours()).slice(-2)}:{('0' + date.getMinutes()).slice(-2)}
        </DateTimeWrapper>
    )
}

export default DateTimeBlock
