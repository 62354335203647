import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAuthData, AuthDataState } from '../../states/utilState'
import { fetchAuthData } from './asyncAction'

export const initialAuthDataState: AuthDataState = {
    auth: {
        company: "",
        page: 0,
        apiid: null,
    },
    isDataexist: false
}

const authDataSlice = createSlice({
    name: 'authData',
    initialState: initialAuthDataState,
    reducers: {
        setAuth: (state, action: PayloadAction<IAuthData>) => {
            console.log(action);
            state.auth = action.payload
        },
        resetAuth: (state, action: PayloadAction<IAuthData>) => {
            state.auth = initialAuthDataState.auth
            state.isDataexist = false;
            console.log(state)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuthData.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                state.auth = action.payload
                state.isDataexist = true
            }
        })
    }
})

export const { setAuth, resetAuth } = authDataSlice.actions

export default authDataSlice.reducer

