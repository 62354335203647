import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/Pages/Main"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/:tenant" element={<Main/>} />
          <Route path="/:tenant/:page" element={<Main/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
