import styled from "styled-components"

export const SensorNameWrapper = styled.div`
  position: relative;
  top: 10.5vh;
  left: 0;
  height: 5vh;
  width: 100%;
  margin: 0;
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  @media screen and (orientation: portrait) {
    font-size: 5vh;
  }
  @media screen and (orientation: landscape) {
    font-size: 2.5vw;
  }
`
