import React, { useEffect, useState } from 'react'

import { Wrapper, HeaderWrapper } from './styledComponents'
import SensorContainer from '../Organisms/ContainerBlock'
import DateTimeBlock from '../Organisms/DateTimeBlock'

import { SensorData, FetchMinMaxTempData } from '../../states/utilState'
import { useOfficeDataState } from '../../reducer/officeData/selectors'
import { useDispatch } from 'react-redux'
import { fetchOfficeData } from '../../reducer/officeData/asyncAction'
import { fetchAuthData } from "../../reducer/authData/asyncAction"
import store from "../../stores"

//import { useMinMaxTempDataState } from '../../reducer/minMaxData/selectors'
//import { fetchMinMaxTempData } from '../../reducer/minMaxData/asyncAction'

const MainTemplate: React.FC<{tenant: string | undefined, page: number}> = (props) => {
    console.log(props.tenant);
    const tenant = props.tenant;
    const officeDataState = useOfficeDataState().officeData
    /* eslint-disable */
    const [officeData, setofficeData] = useState<SensorData[]>()
    const [placeAmount, setPlaceAmount] = useState(0)
    /* eslint-enable */
    const dispatch = useDispatch()
    var timer: NodeJS.Timeout
    //var timer2: NodeJS.Timeout

    const polling = () => {
        timer = setTimeout(()=>{
            /*
            dispatch(fetchOfficeData())
            polling()
            setofficeData([...officeDataState.sensorDatas])
            setPlaceAmount(officeDataState.sensorDatas.length)
            */
            const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
            Promise.resolve(d1).then(() => {
                dispatch(fetchOfficeData(store.getState().authData.auth))
            })
            polling()
            setofficeData([...officeDataState.sensorDatas])
            setPlaceAmount(officeDataState.sensorDatas.length)
        }, 60 * 1000)
    }
    useEffect(()=>{
        /*
        dispatch(fetchOfficeData())
        polling()
        return ()=> clearTimeout(timer)
        */
        const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
        Promise.resolve(d1).then(() => {
            dispatch(fetchOfficeData(store.getState().authData.auth))
        })
        polling()
        return ()=> clearTimeout(timer)
    },[setofficeData,officeDataState.isDataexist])  // eslint-disable-line

    //const minMaxDataState = useMinMaxTempDataState().minMaxData
    /* eslint-disable */
    //const [minMaxData, setMinMaxData] = useState<FetchMinMaxTempData>()
    /* eslint-enable */

    /*
    const minMaxPolling = () => {
        timer2 = setTimeout(()=>{
            dispatch(fetchMinMaxTempData())
            minMaxPolling()
            setMinMaxData(minMaxDataState.temp)
        }, 60 * 60 * 1000)
    }
    useEffect(()=>{
        dispatch(fetchMinMaxTempData())
        minMaxPolling()
        return ()=> clearTimeout(timer2)
    },[setMinMaxData,minMaxDataState.isDataexist])  // eslint-disable-line
    */

    return (
        <Wrapper >
            <HeaderWrapper>
                <DateTimeBlock />
            </HeaderWrapper>

            <SensorContainer sensorData={officeDataState.sensorDatas} page={props.page}></SensorContainer>
        </Wrapper>
    )
}

export default MainTemplate