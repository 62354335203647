import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { FetchAuthDataSuccess, AuthData } from '../../states/utilState'

const getAPIuid = async (tenant: string) => {
    try {
        const url: string = "https://jsq305drm9.execute-api.ap-northeast-1.amazonaws.com/version1/uid/" + tenant;
        const result = await axios.get(url)
        return String(result.data.userId)
    } catch (err) {
        console.log(err)
        return null
    }
}

export const fetchAuthData = createAsyncThunk(
    'authData/fetchAuthData',
    async (authInfo: AuthData) => {
        try {
            let uid: string | null = authInfo.apiid
            if (!uid) {
                uid = await getAPIuid(authInfo.company)
            }
            const ret: FetchAuthDataSuccess = {
                company: authInfo.company,
                page: authInfo.page,
                apiid: uid,
            }
            return ret;
        } catch (err) {
            console.log(err)
        }
    }
)