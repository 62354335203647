import styled from "styled-components"

export const EvaluationWrapper = styled.div`
  position: relative;
  display: flex;
  top: 13vh;
  left: 4%;
  height: 16vh;
  width: 92%;
  border-radius: 2vh;
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  line-height: 1;
  padding:  0;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  @media screen and (orientation: portrait) {
    font-size: 10vh;
  }
  @media screen and (orientation: landscape) {
    font-size: 5.5vw;
  }
`
