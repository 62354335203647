import {
    ContainerWrapper
} from "./styledComponents"

import {
    SensorBlockWrapper
} from "../SensorBlock/styledComponents"

import SensorName from "../../Molecules/SensorName/index"
import Evaluation from "../../Molecules/Evaluation/index"
import DisplayDataBlock from "../../Molecules/DisplayData/index"

import { SensorData } from "../../../states/utilState"

const SensorContainer:React.FC<{sensorData: SensorData[], page: number}> = (props) => {
    return (
        <ContainerWrapper>
            {makeSensorBlockComponent(props.sensorData, props.page)}
        </ContainerWrapper>
    )
}

export default SensorContainer

const makeSensorBlockComponent = (datas: SensorData[], page: number) => {
    return datas.map((data, index)=> {
        if ((page === 0 && index < 3)) {
            let co2Msg: string = "";
            let cls = "";
            if (data.co2.ppm < 1000) {
                co2Msg = "良好";
                cls = "good";
            } else {
                co2Msg = "換気が必要";
                cls = "bad";
            }
            return (
                <SensorBlockWrapper className={cls}>
                    <SensorName placeName={data.placeName}></SensorName>
                    <Evaluation msg={co2Msg}></Evaluation>
                    <DisplayDataBlock sensorData={data}></DisplayDataBlock>
                </SensorBlockWrapper>
            )
        } else if (page > 0) {
            if (index === page - 1) {
                let co2Msg: string = "";
                let cls = "";
                if (data.co2.ppm < 1000) {
                    co2Msg = "良好";
                    cls = "good";
                } else {
                    co2Msg = "換気が必要";
                    cls = "bad";
                }
                return (
                    <SensorBlockWrapper className={cls}>
                        <SensorName placeName={data.placeName}></SensorName>
                        <Evaluation msg={co2Msg}></Evaluation>
                        <DisplayDataBlock sensorData={data}></DisplayDataBlock>
                    </SensorBlockWrapper>
                )
            }
        } else {
            return (<></>);
        }
    })
}
