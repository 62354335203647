import {
    EvaluationWrapper
} from "./styledComponents"

const Evaluation:React.FC<{msg: string}> = (props) => {
    return (
        <EvaluationWrapper>{props.msg}</EvaluationWrapper>
    )
}

export default Evaluation

