import { Store, combineReducers } from 'redux'
import officeDataSlice, {initialState as officeDataInitialState } from "./officeData/slice"
//import minMaxDataSlice, {initialMinMaxState as minMaxDataInitialState } from "./minMaxData/slice"
import authDataSlice, {initialAuthDataState as authDataInitialState } from "./authData/slice"

export const rootReducer = combineReducers({
    officeData: officeDataSlice,
    //minMaxData: minMaxDataSlice,
    authData: authDataSlice,
})

export const preloadedState = () => {
    return {
        officeData: officeDataInitialState,
        //minMaxData: minMaxDataInitialState,
        authData: authDataInitialState,
    }
}

export type StoreState = ReturnType<typeof preloadedState>

export type ReduxStore = Store<StoreState>