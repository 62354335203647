import styled from "styled-components"

export const ContainerWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 0;
  justify-content: space-between;
  z-index: 1;
`
