import { DisplayDataBlockWrapper, DisplayDataWrapper, SensorIconWrapper, SensorCaptionWrapper, SensorDataWrapper } from "./styledComponents"
import { TempIcon, HumIcon, CO2Icon, LightIcon, SoundIcon } from '../../Atoms/Image'
import { SensorData } from "../../../states/utilState"


const DisplayDataBlock:React.FC<{sensorData: SensorData}> = (props) => {
    let strVol: string;
    let strLight: string;
    let strCO2: string;

    let co2cls: string = "data-normal";
    let tempcls: string = "data-normal";
    let humcls: string = "data-normal";
    let volcls: string = "data-normal";
    let lightcls: string = "data-normal";

    if (props.sensorData.volume.db < 0) {
        strVol = "-";
    } else {
        strVol = props.sensorData.volume.db.toString();
    }

    if (props.sensorData.light.lx < 0) {
        strLight = "-";
    } else {
        strLight = props.sensorData.light.lx.toString();
    }

    if (props.sensorData.co2.ppm < 0) {
        strCO2 = "-";
    } else {
        strCO2 = props.sensorData.co2.ppm.toString();
    }

    if (props.sensorData.co2.ppm >= 1000) {
        co2cls = "data-warning";
    }

    if (props.sensorData.temp.temp >= 28) {
        tempcls = "data-caution";
    }

    if (props.sensorData.hum.hum >= 70) {
        humcls = "data-caution";
    }

    if (props.sensorData.volume.db >= 80) {
        volcls = "data-caution";
    }

    if (props.sensorData.light.lx >= 1000) {
        lightcls = "data-caution";
    }

    return (
        <DisplayDataBlockWrapper>

            <DisplayDataWrapper className={co2cls}>
                <SensorIconWrapper><CO2Icon level={props.sensorData.co2.level}></CO2Icon></SensorIconWrapper>
                <SensorCaptionWrapper>CO2</SensorCaptionWrapper>
                <SensorDataWrapper>{strCO2}<span className="unit">ppm</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper className={tempcls}>
                <SensorIconWrapper><TempIcon level={props.sensorData.temp.level}></TempIcon></SensorIconWrapper>
                <SensorCaptionWrapper>温度</SensorCaptionWrapper>
                <SensorDataWrapper>{props.sensorData.temp.temp}<span className="unit">℃</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper className={humcls}>
                <SensorIconWrapper><HumIcon level={props.sensorData.hum.level}></HumIcon></SensorIconWrapper>
                <SensorCaptionWrapper>湿度</SensorCaptionWrapper>
                <SensorDataWrapper>{props.sensorData.hum.hum}<span className="unit">%</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper className={volcls}>
                <SensorIconWrapper><SoundIcon level={props.sensorData.volume.level}></SoundIcon></SensorIconWrapper>
                <SensorCaptionWrapper>音量</SensorCaptionWrapper>
                <SensorDataWrapper>{strVol}<span className="unit">dB</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper className={lightcls}>
                <SensorIconWrapper><LightIcon level={props.sensorData.light.level}></LightIcon></SensorIconWrapper>
                <SensorCaptionWrapper>照度</SensorCaptionWrapper>
                <SensorDataWrapper>{strLight}<span className="unit">lx</span></SensorDataWrapper>
            </DisplayDataWrapper>

        </DisplayDataBlockWrapper>
    )
}

export default DisplayDataBlock

