import React from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setAuth } from "../../reducer/authData/slice"
import MainTemplate from '../Template/MainTemplate'

const Main: React.FC = () => {
    const dispatch = useDispatch()
    const params = useParams();
    let tenant: string = "";
    let page: number = 0;
    if (params.tenant !== undefined) {
        tenant = params.tenant;
    }
    if (params.page !== undefined) {
        page = parseInt(params.page, 10);
    }
    dispatch(setAuth({company: tenant, page: page, apiid: null}))
    return (
        <MainTemplate tenant={params.tenant} page={page}>
        </MainTemplate>
    )
}

export default Main