import styled from "styled-components"

export const DateTimeWrapper = styled.div`
  position: absolute;
  top: 2vh;
  left: 0;
  width: 100vw;
  padding: 0;
  color: #fff;
  margin: 0;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  @media screen and (orientation: portrait) {
    font-size: 4vh;
    & > span.dow {
      font-size: 3vh;
    }
  }
  @media screen and (orientation: landscape) {
    font-size: 2.5vw;
    & > span.dow {
      font-size: 2vw;
    }
  }

`
