import styled from "styled-components"

export const DisplayDataBlockWrapper = styled.div`
  position: relative;
  display: flex;
  top: 15vh;
  left: 4%;
  height: 60vh;
  width: 92%;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  line-height: 1;
  padding:  0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media screen and (orientation: portrait) {
    font-size: 10vh;
  }
  @media screen and (orientation: landscape) {
    font-size: 5.5vw;
  }
`
export const DisplayDataWrapper = styled.div`
  position: relative;
  width: 100%;
  line-height: 1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 3vw;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 900;
  height: 11vh;
  margin-bottom: 1vh;
  border-radius: 2vh;
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  color: #333;
  background-color: #fff;
  &.data-normal {
    color: #333;
    background-color: #fff;
  }
  &.data-caution {
    color: #333;
    background-color: #ffb750;
  }
  &.data-warning {
    color: #fff;
    background-color: #f49688;
  }
  @media screen and (orientation: portrait) {
    font-size: 8vh;
  }
  @media screen and (orientation: landscape) {
    font-size: 3vw;
  }
`
export const SensorIconWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 16%;
  justify-content: center;
  align-items: center;
  & > img {
    height: 8vh;
  }
`

export const SensorCaptionWrapper = styled.div`
  display: flex;
  width: 28%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  @media screen and (orientation: portrait) {
    font-size: 6vh;
  }
  @media screen and (orientation: landscape) {
    font-size: 2.7vw;
  }
`

export const SensorDataWrapper = styled.div`
  display: flex;
  width: 56%;
  height: 100%;
  padding-left: 0.5vw;
  text-align: center;
  align-items: center;
  font-size: 3.8vw;
  font-weight: 900;
  @media screen and (orientation: portrait) {
    font-size: 8vh;
    & > span.unit {
      padding-top: 1.5vw;
      font-size: 6.5vh;
      font-weight: 700;
    }
  }
  @media screen and (orientation: landscape) {
    font-size: 3.8vw;
    & > span.unit {
      padding-top: 0.6vw;
      font-size: 3.2vw;
      font-weight: 700;
    }
  }
`

