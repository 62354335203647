import styled from 'styled-components';
export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: Roboto, 'Noto Sans JP', helvetica;
  background-color: #21aaa8;
`

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  background-color: #333;
  z-index: 2;
`
